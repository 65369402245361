import React from "react";
import { Container, Row, Col } from "reactstrap";
function ContactUs() {
  return (
    <>
      <div
        className="section "
        style={{
          background: "#000",
          alignItems: "center",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div>
                <h3 className="text-400 pt-3 text-white">For all general enquiries contact: </h3>
                <h3 className="text-white">
                  <i className="fa fa-envelope-o mr-5" aria-hidden="true"></i>{" "}
                  <a className="text-white" href={`mailto:events@khaleejtimes.com`}>
                    events@khaleejtimes.com
                  </a>
                  <br />
                  <i className="fa fa-phone mr-5" aria-hidden="true"></i>{" "}
                  <a className="text-white" href={`tel:800KT`}>
                    800KT
                  </a>
                </h3>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
// const content = [{ title: "", link: "events@khaleejtimes.com" }];

export default ContactUs;
