import React from "react";
import Navbar from "componentsDubai/Navbar.js";
// import ProfilePageHeader from "componentsDubai/Headers/ProfilePageHeader.js";

import Footer from "componentsDubai/Footer.js";
import AgendaFull from "componentsDubai/AgendaFull.js";
// import SponsorNew from "componentsQatar/SponsorNew.js";
// import { sponsorsPage } from "./content";

function RegisterPage() {
  return (
    <>
      <Navbar />
      {/* <ProfilePageHeader title={"2024 Agenda"} bgColor="#fff" /> */}
      <AgendaFull />
      {/* <SponsorNew sponsors={sponsorsPage} /> */}
      <Footer />
    </>
  );
}

export default RegisterPage;
