import React from "react";

import Footer from "components/Footer.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import SpeakerCardAll from "components/SpeakerCardAll.js";
import Navbar from "components/Navbar.js";
import { newspeakers, chairman } from "./content";


function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="2023 Speakers" bgColor="#fff" />
      <SpeakerCardAll speakers={newspeakers} chairman={chairman} />

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
