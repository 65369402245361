import React from "react";
import Navbar from "componentsQatar/Navbar.js";
import ProfilePageHeader from "componentsQatar/Headers/ProfilePageHeader.js";
import Register from "componentsQatar/StartupLounge";
import Footer from "componentsQatar/Footer.js";

function RegisterPage({ type, title, subject }) {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title={""} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type={type} subject={subject} />
      </div>

      <Footer />
    </>
  );
}

export default RegisterPage;
