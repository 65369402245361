import React from "react";
import { Row, Container, Col } from "reactstrap";

function DemoFooter() {
  return (
    <footer
      className="footer py-5"
      style={{
        background: "#000",
      }}
    >
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col lg={6} className="mb-4 text-center">
            <img
              src="/assets/dubai/bitDubai2024WhiteNew.png"
              alt="vibe"
              width="100%"
              style={{ objectFit: "contain", width: "auto" }}
            />
          </Col>
          <Col lg={1} />
          <Col lg={4} xs={8} className={"text-left align-self-center"}>
            {/* {links.map((l) => (
              <h5 className="mt-0" key={l.title}>
                <a href={l.link} style={{ color: "#fff" }} className="text-400">
                  {l.title}
                </a>
              </h5>
            ))} */}

            <p className="text-600 mt-3" style={{ color: "#fff" }}>
              STAY CONNECTED
            </p>

            {social.map((s) => (
              <a
                key={s.name}
                className={`btn-primary text-400 pt-4 px-4`}
                style={{
                  color: "#1d1960",
                  backgroundColor: "transparent",
                }}
                href={s.link}
              >
                <i className={`fa fa-${s.name}`} style={{ fontSize: "2rem", color: "#fff" }} />
              </a>
            ))}
          </Col>
          <Col lg={12} className="mt-5">
            <p className="text-400 text-white text-center">
              For sponsorship enquiries, contact:{" "}
              <a
                href="mailto:events@khaleejtimes.com"
                className="text-600"
                style={{ color: "#fff" }}
              >
                events@khaleejtimes.com
              </a>{" "}
              or call on : 800KT
              <br />
            </p>
            <p className="text-400 text-white text-center">
              © 2024 Khaleej Times Events All Rights Reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;

const social = [
  {
    name: "facebook",
    link: "https://www.facebook.com/khaleejtimes/",
  },
  {
    name: "twitter",
    link: "https://twitter.com/khaleejtimes",
  },
  {
    name: "linkedin",
    link: "https://www.linkedin.com/showcase/khaleej-times-events/",
  },
];

// const links = [
//   { title: "HOME", link: "/qatar/#" },
//   { title: "OVERVIEW", link: "/qatar/#about" },
//   { title: "AGENDA", link: "/qatar/#agenda" },
//   { title: "SPEAKERS", link: "/qatar/#speakers" },
// ];
