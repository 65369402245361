import React, { useContext } from "react";

import { Container, Row, Col, Card, Modal } from "reactstrap";
import SpeakerModal from "components/SpeakerModal.js";
import Slider from "react-slick";
import { DataContext } from "DataContainer";

function SpeakerGrid() {
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  const { speakers } = useContext(DataContext);

  function SpeakerCard({ speaker, chairman }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto p-3"
          style={{
            borderRadius: 0,
            boxShadow: "none",
            background: "transparent",
          }}
        >
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <img
                  src={speaker.image || require("assets/speakers/placeholder.png")}
                  alt="speaker"
                  width="100%"
                  // className=" rounded-circle"
                />
              </Col>

              <Col xs={12} className="px-0">
                <div className="text-center text-dark">
                  <h4 className="text-600  mt-2 mb-0 d-block px-0">{speaker.name}</h4>
                  <p className="text-400 m-0">{speaker.title}</p>
                  <p className="text-400 m-0 d-block px-0">{speaker.company}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
        }}
        className="section p-0"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            {size ? (
              speakers.map((speaker, index) => (
                <Col className="mx-auto px-0" lg={3} md={6} key={index}>
                  <SpeakerCard speaker={speaker} />
                </Col>
              ))
            ) : (
              <Col xs={11} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)}>
          <div className="section profile-content bg-primary">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => updateModal(e)}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                color: "#888888",
              }}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default SpeakerGrid;
