import React from "react";
import Navbar from "componentsQatar/Navbar.js";
import ProfilePageHeader from "componentsQatar/Headers/ProfilePageHeader.js";

import Footer from "componentsQatar/Footer.js";

import ContactUs from "componentsQatar/ContactUs";
// import SponsorNew from "componentsQatar/SponsorNew.js";
// import { sponsorsPage } from "./content";

function RegisterPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title={"Contact Us"} bgColor="#fff" />
      <ContactUs />
      {/* <SponsorNew sponsors={sponsorsPage} /> */}
      <Footer />
    </>
  );
}

export default RegisterPage;
