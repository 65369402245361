import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import "assets/css/paper-kit.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Dubai
import Home from "./views/home.js";
import RegisterPage from "./views/Register.js";
import ThankYouRegister from "./views/ThankYouRegister";

import PrivacyPolicy from "./views/PrivacyPolicy";
import Termsconditons from "./views/Termsconditions";
import SponsorSingle from "./views/SponsorSingle";
import Speakers from "./views/Speakers";
import ContactUs from "./views/ContactUs.js";

import DataProvider from "./DataContainer";
import IndexQatar from "indexQatar.js";
import DataContainerQatar from "DataContainerQatar.js";
import IndexRiyadh from "indexRiyadh.js";
import IndexDubai from "indexDubai2024.js";

ReactDOM.render(
  <DataProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Home {...props} />} />
        <Route
          path="/register"
          exact
          render={(props) => (
            <RegisterPage
              {...props}
              type="REGISTER"
              title="Register Now"
              subject=""
            />
          )}
        />

        <Route
          path="/contact"
          exact
          render={(props) => <ContactUs {...props} />}
        />
        <Route
          path="/request-brochure"
          exact
          render={(props) => (
            <RegisterPage
              {...props}
              type="AGENDA"
              title="Download Brochure"
              subject=""
            />
          )}
        />
        <Route
          path="/sponsors/:sponsor"
          exact
          render={(props) => <SponsorSingle {...props} />}
        />
        <Route
          path="/sponsor"
          exact
          render={(props) => (
            <RegisterPage
              {...props}
              type="SPONSOR"
              title="Sponsor - BIT Summit"
              subject=""
            />
          )}
        />
        <Route
          path="/privacy-policy"
          exact
          render={(props) => <PrivacyPolicy {...props} />}
        />
        <Route
          path="/terms"
          exact
          render={(props) => <Termsconditons {...props} />}
        />
        <Route
          path="/speakers"
          exact
          render={(props) => <Speakers {...props} />}
        />

        <Route
          path="/thank-you/:id"
          exact
          render={(props) => <ThankYouRegister {...props} />}
        />
        <DataContainerQatar>
          <Route path="/qatar">
            <IndexQatar />
          </Route>
          <Route path="/riyadh">
            <IndexRiyadh />
          </Route>
          <Route path="/dubai">
              <IndexDubai />
            </Route>
        </DataContainerQatar>
      </Switch>
    </BrowserRouter>
  </DataProvider>,
  document.getElementById("root")
);
