// import { SponserDubai2024 } from "config";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "reactstrap";

function Sponsor() {
  const [Sponsors2024, setSponsors2024] = useState([])
  useEffect(() => {
    fetch(`https://api.khaleejtimesevents.com/bitDubai/data.json?v=0.3.${new Date()}`, {
      method: "get",
    })
      .then((response) => response.json())
      .then((data) => {
        console.error(data)
        setSponsors2024(data.sponsors2024 || []);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <div style={{ background: "#f5f5f5" }} className="section" data-parallax={true}>
        <Container>
          <Row>
            <Col lg={12}>
              <h1 className="text-300 text-center text-dark mb-5">2024 Sponsor & Partners</h1>
            </Col>
          </Row>
          <Row>
            {Sponsors2024.map((s) => (
              <Col lg={4} style={{ margin: '0px auto' }}>
                <h3 className="text-300 text-center text-dark mb-1">{s.name ? s.name : <>&nbsp;</>}</h3>
                <a href={s.url} target="_BLANK" rel="noreferrer">
                  <Card className="rounded-0">
                    <Container fluid>
                      <img src={s.image} alt={s.name} width="100%" className="rounded-0 pb-3" />
                    </Container>
                  </Card>
                </a>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Sponsor;
