import { DataContextQatar } from "DataContainerQatar";
import moment from "moment";
import React, { useContext } from "react";
import {
  Container,
  Button,
  Row,
  Col,
  TabContent,
  TabPane,
  Collapse,
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";
import { groupBy } from "views/content";

function Agenda() {
  const [activeTab, setActiveTab] = React.useState("2");

  const [open, toggleOpen] = React.useState({
    open: -1,
  });
  const { agenda } = useContext(DataContextQatar);
  const dayWiseAgenda = groupBy("day")(agenda);

  function AgendaCard({ data, hideTime }) {
    const size =
      document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
        ? true
        : false;
    return data.map((d, index) => (
      <Container>
        <Row>
          {!hideTime && (
            <Col lg={2} className="align-self-center">
              <h4 className="text-600 mt-0 text-dark">
                <b>
                  {moment.utc(d.startAt).format("HH:mm")} - {moment.utc(d.endAt).format("HH:mm")}{" "}
                </b>{" "}
                {!size && d.description && (
                  <i
                    className="fa fa-caret-down ml-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleOpen(open === index ? -1 : index)}
                    aria-hidden="true"
                  ></i>
                )}
              </h4>
            </Col>
          )}
          <Col lg={9} className="align-self-center text-dark  py-4">
            <h4 className="text-400 m-0">
              <b>
                {d.type ? `${d.type} - ` : ""}
                {d.title}
              </b>
            </h4>
            {d.text &&
              d.text.map((t) => (
                <h3 className="text-300 m-0 mt-1" dangerouslySetInnerHTML={{ __html: t }} />
              ))}
          </Col>
          {size && d.description && (
            <>
              <Col lg={1} className="align-self-center">
                {/* <h3 className="text-400 mt-0 text-dark">
                  <i
                    class="fa fa-caret-down"
                    aria-hidden="true"
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleOpen(open === index ? -1 : index)}
                  ></i>
                </h3> */}
              </Col>
              <Col lg={2} />
            </>
          )}
          <Col lg={9}>
            {/* <Collapse isOpen={open === index}> */}
            <p className="text-400 text-dark" dangerouslySetInnerHTML={{ __html: d.description }} />
            {/* </Collapse> */}
          </Col>
        </Row>
      </Container>
    ));
  }

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "transparent",
          // backgroundImage: "linear-gradient(315deg, #ffffff 0%, #1b2845 74%)",

          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="section pb-0"
        data-parallax={true}
      >
        <Container>
          <h1 className="header-text text-dark mb-4">EVENT AGENDA</h1>
          <Row>
            <Col md="12">
              <div className="nav-tabs-navigation text-left mt-3 mb-3">
                <div className="nav-tabs-wrapper">
                  <Nav tabs className="nav-fill">
                    <NavItem>
                      <NavLink
                        className={`text-left p-0 py-3 ${activeTab === "2" ? "active" : ""}`}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        <h4 className="text-400 mt-0 pl-3">
                          <b>26th May</b>
                        </h4>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`text-left p-0 py-3 ${activeTab === "1" ? "active" : ""}`}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        <h4 className="text-400 mt-0 pl-3">
                          <b>27th May</b>
                        </h4>
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink
                        className={`text-left p-0 py-3 ${activeTab === "3" ? "active" : ""}`}
                        onClick={() => {
                          toggle("3");
                        }}
                      >
                        <h4 className="text-400 mt-0 pl-3">
                          <b>28th May</b>
                        </h4>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
            </Col>
            <Col md="12">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={dayWiseAgenda[1] || []} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="2">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={dayWiseAgenda[2] || []} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="3">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={dayWiseAgenda[3] || []} hideTime />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="py-3 mb-5 text-center">
              <Button
                href="/qatar/register"
                className="navbar-item-custom text-400 text-dark rounded-0 "
                style={{
                  backgroundImage: "linear-gradient(315deg, #639ccc 0%, #000000 96%)",
                  border: "2px solid #fff",
                }}
                size="lg"
              >
                <span
                  style={{
                    color: "#fff",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                  className="text-600"
                >
                  REGISTER NOW <i class="fa fa-arrow-right" />
                </span>
              </Button>
            </Col>
            {/* <Col lg={12} className="pt-3 text-center"></Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Agenda;
