import React, { useContext } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import Slider from "react-slick";
import { DataContext } from "DataContainer";
function Sponsor({ sponsors }) {
  let pageHeader = React.createRef();
  // const [overlay, setOverlay] = React.useState(false);
  const settings = {
    // dots: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 3,
    className: "p-10",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          row: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          row: 1,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const { sponsors: allSponsors } = useContext(DataContext);

  return (
    <div
      style={{
        backgroundColor: "transparent",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: 5,
      }}
      className="section section-with-space"
      data-parallax={true}
      ref={pageHeader}
    >
      <Container>
        <Row>
          <Col lg={12}>
            <h1 className="text-300 text-uppercase text-center text-dark mb-5">
              PAST SPONSORS AND PARTNERS
            </h1>
          </Col>
        </Row>
        <Row style={{}} className="pt-4 pb-2">
          <Col lg={12} xs={12}>
            <Slider {...settings}>
              {allSponsors.map((s, i) => (
                <Card className="rounded-0">
                  <Container fluid>
                    <Row>
                      <Col lg={12}>
                        <img src={s.image} alt={s.name} width="100%" className="rounded-0" />
                      </Col>
                    </Row>
                  </Container>
                </Card>
              ))}
            </Slider>
          </Col>
        </Row>
        {/* <Row>
          <Col lg={12} className="text-center">
            <Button
              href="/partners"
              className="btn my-2 text-center px-5"
              color="primary"
              size="lg"
            >
              View All
            </Button>
          </Col>
        </Row> */}
      </Container>
    </div>
  );
}

export default Sponsor;
