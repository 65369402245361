import React from "react";
import { Container, Row, Col, Button, Card, CardImg } from "reactstrap";

import "react-circular-progressbar/dist/styles.css";

import SpeakerCard from "./SpeakerCard";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const mdSize =
    document.body.clientWidth >= 1324 ||
    document.documentElement.clientWidth >= 1324
      ? true
      : false;

  return (
    <>
      <section id="about">
        <div
          style={{
            backgroundImage: `url(${require("assets/images/hand-pointing-currency-blockchain-technology-background.png")})`,
            backgroundSize: size ? "100%" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
          className="section py-0"
          data-parallax={true}
          ref={pageHeader}
        >
          <Container>
            <Row className="">
              <Col
                md={7}
                xs={12}
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              >
                <div
                  className="text-dark"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 4rem 1rem",
                  }}
                >
                  <h1
                    className="header-text text-left"
                    style={{ fontSize: size && "5rem" }}
                  >
                    OVERVIEW
                  </h1>
                  <br />
                  <br />
                  <p className="text-400  text-left">
                    Past 2 years have been the biggest years in digital
                    transformation of banks along with the evolution of Fintech,
                    what was believed to be a brief disruption has now become
                    the new norm as we move towards a new world order.
                    Digitization is at the heart of almost every business in
                    this new order, with fintech innovations going above and
                    beyond to meet the evolving customer expectations and
                    banking needs. The definition of customer and money is
                    getting redefined in this era. As BigTech and Fintech
                    companies close in on the banking industry faster than ever,
                    Banks are left with no option but to adopt digital banking
                    and fintech's agility, to align with the evolving market
                    dynamics and customer needs.
                    <br /> <br />
                    Banking and Fintech - The two sectors, have always been a
                    strong advocate of technology, the truth is that
                    digitalization has always been a necessity for the banking
                    sector to stay ahead of the curve. Digital experience is
                    what counts for every business, that is were Fintech play a
                    critical role. The rise of emerging technologies such as metaverse, artificial intelligence has only
                    increased the adoption rate of technology which has made
                    banking and fintech one of the most future oriented
                    industries. From Al powered chatbots to mobile banking, from
                    loT powered devices to cashless transactions, the technology
                    has empowered the banks to deliver the next level customer
                    experience and at the same time transform their own
                    processes making it more agile, responsive and scalable in
                    real time.
                  </p>
                  <br />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url(${require("assets/images/61769.jpg")})`,
            backgroundSize: size ? "100%" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          <Container>
            <Row>
              {" "}
              <Col md={8} xs={12}>
                <div
                  className="text-dark"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 4rem 1rem",
                  }}
                >
                  <h4 className="text-400  text-left">
                    Khaleej Times has not only witnessed the rise of the banking
                    and fintech sector in the region but also witnessed its
                    contribution in nation building and helping the GCC
                    countries become the economic powerhouses they are today.
                    While technology has been discussed a lot of times,
                    Fintech's capabilities, its impact and use in the banking
                    sector has never been discussed on such a massive and wide
                    spread platform ever. Bringing the best of global banking
                    experts and fintech leaders,{" "}
                    <b>
                      Khaleej Times presents Banking, Innovation and Technology
                      Summit 2023.
                    </b>
                    <br />
                    <br />
                  </h4>
                  <br />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: "transparent",
            backgroundImage: `url(${require("assets/images/creative1.png")})`,
            backgroundSize: size ? "100%" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          <Container>
            <Row className="justify-content-end">
              {" "}
              <Col md={7} xs={12}>
                <div
                  className="text-dark"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 4rem 1rem",
                  }}
                >
                  <h4 className="text-400  text-left">
                    <b>BIT 2023 </b>will further confirm GCC's rise as a finance
                    technology hotspot and its commitment to provide a better,
                    safe and progressive financial infrastructure to its people.
                    As a sequel to Digibank 3rd edition, BIT 2023 will be a
                    gathering of the foremost, most innovative and sought-after
                    thought leaders, Innovators and service providers, deep
                    diving into the latest trends, opportunities and challenges
                    facing both the sectors.
                    <br />
                    <b>
                      The event will be taking place in Dubai, UAE on the 15th
                      and 16th of November.
                    </b>
                  </h4>
                  <br />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: "transparent",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          {" "}
          <Container>
            <h1
              className="header-text text-left mb-4"
              style={{ fontSize: size ? "3rem" : "2rem" }}
            >
              GCC Fintech:
              <br />
              From Vision to Reality in Seven Years
            </h1>
            <Row className="justify-content-end">
              {" "}
              <Col md={5} xs={12}>
                <div
                  className="text-dark"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 4rem 1rem",
                  }}
                >
                  <h4 className="text-400  text-left">
                    In 2015, we set our sights on the GCC's fintech potential,
                    envisioning a soaring trajectory. Fast forward seven years,
                    and the GCC fintech landscape has not only taken flight but
                    has achieved remarkable heights: From a single fintech hub
                    in 2018, the GCC now boasts four dynamic hubs in 2022: the
                    Abu Dhabi Global Market (ADGM), Bahrain FinTech Bay, Fintech
                    Saudi, and the FinTech Hive at the Dubai International
                    Financial Centre (DIFC). This rapid proliferation of fintech
                    hubs in the GCC is a testament to the region's remarkable
                    growth, mirroring the expansion witnessed throughout the
                    entire MENA region.
                  </h4>
                  <br />
                </div>
              </Col>
              <Col lg={7}>
                <img
                  src={require("assets/images/Asset1.png")}
                  alt={"wevalue"}
                  width="100%"
                  className="rounded-0"
                />
                <h5 className="text-600 mt-2">
                  The proliferation of fintech hubs accross the Middle East and
                  North Africa -{" "}
                  <a
                    className="text-600"
                    href="https://www.strategyand.pwc.com/m1/en/strategic-foresight/sector-strategies/financial-sector-consulting/fintechbuilding"
                  >
                    Source
                  </a>
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: "transparent",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          {" "}
          <Container>
            <h1
              className="header-text text-left mb-4"
              style={{ fontSize: size ? "3rem" : "2rem" }}
            >
              Unveiling the Rising Star:
              <br />
              Fintech in the MENA Region
            </h1>
            <Row className="justify-content-start">
              {content.map((c) =>
                c.type === "IMAGE" ? (
                  <Col lg={6}>
                    <img
                      src={require("assets/images/Asset2.png")}
                      alt={"wevalue"}
                      width="100%"
                      className="rounded-0"
                    />
                    <h5 className="text-600 mt-2">
                      <a className="text-600" href={c.source}>
                        Source
                      </a>
                    </h5>
                  </Col>
                ) : (
                  <Col md={c.grid} xs={12}>
                    <div
                      className="text-dark"
                      // style={{ padding: size ? "0rem 1rem 3rem 1rem" : "3rem 1rem 4rem 1rem" }}
                    >
                      <h5 className="text-600 mt-2">{c.title}</h5>
                      <p className="text-400  text-left">
                        <ul>
                          {c.points.map((p) => (
                            <li>{p}</li>
                          ))}
                        </ul>
                      </p>
                      <br />
                    </div>
                  </Col>
                )
              )}
            </Row>
          </Container>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: "transparent",
            backgroundImage:
              size && `url(${require("assets/images/Asset3.png")})`,
            backgroundSize: size ? "100%" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          {" "}
          <Container>
            <h1
              className="header-text text-left mb-4"
              style={{ fontSize: size ? "3rem" : "2rem" }}
            >
              Banking Brilliance in MENA:
              <br />
              Resilience, Growth and Gulf Dominance
            </h1>
            <Row className="justify-content-start">
              {" "}
              <Col md={5} xs={12}>
                <div className="text-dark">
                  <h4 className="text-400  text-left">
                    In the face of the global pandemic, MENA's banking sector
                    has not only weathered the storm but emerged stronger. The
                    top banks in the region have witnessed impressive growth,
                    with their combined assets surging by 13% to a formidable
                    $2.5 trillion by the close of 2021, compared to $2.3
                    trillion in 2020. Profits, too, have soared, with a
                    remarkable 37% increase, reaching over $34 billion. MENA's
                    banking sector stands as a testament to resilience,
                    adaptability, and its unwavering commitment to progress in
                    the face of global challenges.
                  </h4>
                  <br />
                </div>
              </Col>
              {!size && (
                <Col lg={12}>
                  <img
                    src={require("assets/images/Asset3M.png")}
                    alt={"wevalue"}
                    width="100%"
                    className="rounded-0"
                  />
                  <h5 className="text-600 mt-2">
                    <a
                      className="text-600"
                      href="https://fintechnews.ae/14536/fintech/middle-east-digital-banking-sector-growing-at-a-43-annual-rate/"
                    >
                      Source
                    </a>
                  </h5>
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </section>

      <section id="speakers">
        <SpeakerCard />
      </section>
    </>
  );
}

export default TextGrid;

const content = [
  {
    title: "Vast Market Potential",
    points: [
      "The MENA region offers boundless opportunities for fintech ventures due to its expansive market and the absence of well-established financial institutions.",
      "In comparison to mature markets like the US and Europe, a staggering 67% of the adult population in MENA is either underbanked or unbanked, totaling a staggering 136 million adults.",
    ],
    grid: 6,
  },
  {
    type: "IMAGE",
    src: require("assets/images/Asset2.png"),
    source:
      "https://fintechnews.ae/15457/fintech/2023s-top-10-fintech-companies-in-the-middle-east/",
  },

  {
    title: "Demographic Advantage",
    points: [
      "MENA's demographics present immense prospects, with over 60% of the population under the age of 30, a stark contrast to Europe's one-third.",
      "The region's population is highly tech-savvy and enthusiastically embraces new digital technologies, exemplified by the UAE, where more than 50% of the population already utilizes digital wallets.",
    ],
    grid: 6,
  },
  {
    title: "Supportive Environment",
    points: [
      `Governments in the region, notably UAE, Saudi Arabia, Egypt, and Bahrain, are wholeheartedly fostering the fintech ecosystem through initiatives such as free zones like the Dubai International
Financial Centre (DIFC) and regulatory sandboxes like DIFC's Innovation Testing License (ITL), Egypt's fintech sandbox, and SAMA's regulatory sandbox in Saudi Arabia.
      The MENA region is also demonstrating`,
      "remarkable openness to cryptocurrency, with UAE and Bahrain leading the charge in crypto adoption and regulation.",
    ],
    grid: 6,
  },
  {
    title: "Thriving Investment Landscape",
    points: [
      `The fintech startup and venture capital scene in MENA is experiencing unprecedented
      growth, with fintech surpassing $819 million in the first half of 2022, nearly doubling the
      previous record.`,
      `Crypto investments have also surged in the region, reaching $187 million, nearly twice
      the previous record, reflecting the remarkable potential of the MENA fintech market.`,
    ],
    grid: 6,
  },
];
