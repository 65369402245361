import React from "react";
import { Route } from "react-router-dom";

import "assets/css/paper-kit.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Home from "./viewsQatar/home.js";
import RegisterPage from "./viewsQatar/Register.js";
import StartupLounge from "./viewsQatar/StartupLounge.js";
import ThankYouRegister from "./viewsQatar/ThankYouRegister";
import PrivacyPolicy from "./viewsQatar/PrivacyPolicy";
import Termsconditons from "./viewsQatar/Termsconditions";
import Speakers from "./viewsQatar/Speakers";
import ContactUs from "./viewsQatar/ContactUs.js";
import ThankYouStartup from "./viewsQatar/ThankYouStartup";
import Agenda from "viewsQatar/Agenda.js";

export default () => (
  <>
    {/* // <Switch> */}
    <Route exact path="/qatar" render={(props) => <Home {...props} />} />
    <Route
      exact
      path="/qatar/register"
      render={(props) => (
        <RegisterPage {...props} type="REGISTER" title="Register Now" subject="" />
      )}
    />

    <Route path="/qatar/contact" render={(props) => <ContactUs {...props} />} />
    <Route path="/qatar/agenda" render={(props) => <Agenda {...props} />} />
    <Route
      path="/qatar/request-brochure"
      render={(props) => (
        <RegisterPage {...props} type="AGENDA" title="Download Brochure" subject="" />
      )}
    />

    <Route
      path="/qatar/sponsor"
      exact
      render={(props) => (
        <RegisterPage {...props} type="SPONSOR" title="Sponsor - BIT Summit" subject="" />
      )}
    />
    <Route path="/qatar/privacy-policy" exact render={(props) => <PrivacyPolicy {...props} />} />
    <Route path="/qatar/terms" exact render={(props) => <Termsconditons {...props} />} />
    <Route path="/qatar/speakers" exact render={(props) => <Speakers {...props} />} />
    <Route
      path="/qatar/startup-lounge"
      exact
      render={(props) => (
        <StartupLounge {...props} title="Fintech Innovations Lounge Program Application Form" />
      )}
    />
    <Route
      path="/qatar/thank-you-startup"
      exact
      render={(props) => <ThankYouStartup {...props} />}
    />
    <Route path="/qatar/thank-you/:id" exact render={(props) => <ThankYouRegister {...props} />} />
  </>
  // </Switch>
);
