import React from "react";

// core components
import Navbar from "../componentsQatar/Navbar.js";
import LandingPageHeader from "componentsQatar/Headers/LandingPageHeader.js";

import TextGrid4 from "componentsQatar/TextGrid4.js";
import SponsorNew from "componentsQatar/SponsorNew.js";
import SpeakerCard from "componentsQatar/SpeakerCard.js";

import PastSponsors from "componentsQatar/PastSponsors.js";
import DemoFooter from "../componentsQatar/Footer.js";

import Agenda from "../componentsQatar/Agenda.js";
import Photos from "../componentsQatar/Photos.js";
// import TextGrib5 from "../componentsQatar/TextGrid5.js";

function Home() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <div
      style={{
        backgroundColor: "transparent",

        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Navbar />
      <section id="home" />
      <LandingPageHeader />

      {/* <TextGrid4 /> */}
      <TextGrid4 />

      <section id="agenda">
        <Agenda />
      </section>
      {/* <Photos /> */}
      <section id="speakers">
        <SpeakerCard />
      </section>

      <section id="partners">
        <SponsorNew />
        <PastSponsors />
      </section>

      <DemoFooter />
    </div>
  );
}

export default Home;
