import React from "react";
import { Container, Row, Col } from "reactstrap";
import './AgendaFull.css';

function Agenda() {
    const agendaData = [
        {
            time: '8:00',
            title: 'Registration & Welcome Coffee',
            description: '',
        },
        {
            time: '8:40',
            title: 'Welcome Address',
            description: '<p class="mt-2">Michael Jabri Pickett, Editor in Chief, Khaleej Times</p>',
        },
        {
            time: '8:45',
            title: 'Opening Keynote Address | Reimagining Core Banking Compliance: Future-Proofing Systems in an Era of Digital Transformation.',
            description: '<ul class="mt-2"><li>Abdul Waheed Rathore, Group Chief Compliance Officer, Dubai Islamic Bank</li></ul>',
        },
        {
            time: '9:00',
            title: 'Strategic Insights | State of Banking and Fintech in the GCC',
            description: '<ul class="mt-2">  <li>Sheinal Jayantilal, Partner, McKinsey & Company</li>   <li>Sagar Shah, Associate Partner, McKinsey & Company</li>   </ul>',
        },
        {
            time: '9:20',
            title: 'Panel Discussion | Governance and FinTech | A Key to Unlocking Capital: How Governance Shapes FinTech Success',
            description: '<p class="mt-2"><b>Moderator: </b>Akshay Chopra, Managing Partner, 237 Ventures</p>   <ul class="mt-2">   <li>Christiane El Habre, Regional Managing Director - Middle East, Apex Group</li>      <li>Arpit Mehta, Co-Founder, Fuze</li>      <li>Kokila Alagh, Founder, KARM Legal Consultants</li>      <li>Lilian Poilpot, Partner, Erevena</li>   </ul>',

        },
        {
            time: '9:55',
            title: 'Panel Discussion | Cards and Loyalty | The Credit Card & Loyalty Convergence: Redefining Customer Engagement in the UAE', description: '<p class="mt-2"><b>Moderator: </b>John Galani, Faculty Member, LIBF MENA</p>   <ul class="mt-2">          <li>Deepak Sarup, Group EVP & CBO, Thriwe</li>      <li>Gina Petersen-Skyrme, Country Business Manager - UAE and Oman, Mastercard</li>       <li>Hisham Issa, Head - Credit Cards and Personal Loans Wealth & Retail Banking, Standard Chartered</li>       <li>Navneet Dave, MD & Co-Head of Processing for the Middle East, Network International</li>    </ul>',
        },
        {
            time: '10:30',
            title: 'Panel Discussion: Green Finance | Exploring the intersection between sustainable finance with fintech – how to deliver on growth and impact',
            description: '<p class="mt-2"><b>Moderator: </b>Jessica Robinson, Partner, Solve Strategies</p>   <ul class="mt-2">   <li>Melody MA, Global Head of Policy & Regulatory Affairs, Head of Legal MENA, ACX Group</li>    <li>Jonathan Keys, Director of Sustainable Finance, ING</li>    <li>Lola Fernandez Flores, Principal, Venture Souq</li></ul>',
        },
        {
            time: '11:00',
            title: 'Networking Coffee Break',
            description: '',
        },
        {
            time: '11:20',
            title: 'Panel Discussion | Remittances | Rethinking Remittances: Thriving Amid Disruption in an Overcrowded Market',
            description: '<p class="mt-2"><b>Moderator: </b> Olivia Bellingham, SVP Mastercard Direct Services-(EEMEA), Mastercard </p>   <ul class="mt-2">   <li>Abhishek Tripathi, Senior Product Director,Careem Pay </li>       <li>Harish Parameswaran, Vice President,Comera Pay</li>       <li>Ananth Srivatsa, Executive Vice President & Head-Remittances, RAKBANK</li>    </ul>',

        },
        {
            time: '11:55',
            title: "Panel Discussion| Payments | The Future of Payments: Pioneering Innovations for UAE's Vision of a Fully Integrated Digital Economy by 2030",
            description: '<p class="mt-2"><b>Moderator: </b>Gaurav Dhar, CEO, Marshal Fintech Partners</p>   <ul class="mt-2">   <li>Imane Adel, EVP Strategy, Paymob</li>       <li>Andrew McCormack ,COO, Al Etihad Payments</li>       <li>Kunal Jhunjhunwala, Founder, Airpay</li>    </ul>',

        },
        {
            time: '12:30',
            title: 'Fireside chat | Banking & Fintech Collab | The Future of Banking and Fintech Collaboration',
            description: '<ul class="mt-2">   <li>Nandan Mer, CEO, Network International</li>             <li>Ronit Ghose, Head of Future of Finance, Citi</li>    </ul>',
        },
        {
            time: '13:00',
            title: 'Panel Discussion | HR | Empowering Workforce Excellence: Talent, Technology, and AI-Driven Transformation in Banking and Fintech',
            description: '<p class="mt-2"><b>Moderator: </b>Fazeela Gopalani, Partner, EY MENA</p>   <ul class="mt-2">   <li>Susana Correia, Head of Search & Staffing MENA, LinkedIn Talent Solutions</li>       <li>Yusra Abdul Ghaffar Baqi, Chief Human Capital Officer, Aafaq Islamic Finance, PSC</li>       <li>Sonali Attri, VP People Business Partner - EEMEA, Mastercard</li>    </ul>',
        },
        {
            time: '13:35',
            title: 'Fireside Chat | Redefining Wealth Management | How Fintech is Transforming Strategies for the Growing UHNWI Segment in the UAE',
            description: '<ul class="mt-2">   <li>Malik Sarwar, Senior Partner, MD- Wealth Management, Global Leader Group</li>             <li>Masroor Batin, CEO, Middle East, Banco Santander International SA, DIFC Branch</li>    </ul>',
        },
        {
            time: '14:00',
            title: 'Panel Discussion- Digital Banking | Banking on AI: Maximizing Efficiency, Profitability, and Customer Trust',
            description: '<p class="mt-2"><b>Moderator: </b>Tristan Brandt, Managing Director, Alvarez & Marsal</p>   <ul class="mt-2">   <li>	Vibhor Mundhada, CEO, NEOPAY</li>       <li>Christoph Koster, CEO, ruya Bank</li>       <li>Suvo Sarkar, Founder & CEO, 3D Advisory</li>           <li>Prateek Vahie, Chief Commercial Officer, Wio Bank</li>    </ul>',
        },
        {
            time: '14:35',
            title: 'Closing Remarks: Banking on Change',
            description: '<ul class="mt-2">   <li>Paul Cox, Retail Banking & Wealth Advisory Specialist</li>    </ul>',

        },
        {
            time: '14:45',
            title: 'Networking Lunch & End of the Summit',
            description: '',

        },
    ];

    function AgendaCard({ data }) {
        return data.map((d) => (
            <Container style={{ backgroundColor: '#ffffffa3', border: '2px solid #f5f5f5', marginBottom: '10px' }} key={d.time}>
                <Row>
                    <Col style={{ borderRight: '2px solid #f5f5f5' }} lg={2} className="align-self-center">
                        <h4 className="text-600 mt-0 text-dark text-center">{d.time}</h4>
                    </Col>
                    <Col lg={9} className="align-self-center text-dark  py-4">
                        <h4 className="text-400 m-0">{d.title}</h4>
                        <div dangerouslySetInnerHTML={{ __html: d.description }}></div>
                    </Col>
                </Row>
            </Container>
        ));
    }

    return (
        <>
            <div className="sectionOLD pb-0 bgHere" data-parallax={true}>
                <div className="py-5" data-parallax="true">
                    <h1 className="header-text text-uppercase text-center text-dark">
                        2024 Agenda
                    </h1>
                </div>
                <Container>
                    <Row>
                        <Col md="12">
                            <Container>
                                <Row>
                                    <Col lg={12} className="px-0 mb-5 agendaData">
                                        <AgendaCard data={agendaData || []} />
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Agenda;
