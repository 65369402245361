import React from "react";

import Footer from "componentsQatar/Footer.js";
import ProfilePageHeader from "componentsQatar/Headers/ProfilePageHeader.js";
import SpeakerCardAll from "componentsQatar/SpeakerCardAll.js";
import Navbar from "componentsQatar/Navbar.js";
import { newspeakers, chairman } from "./content";


function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="2023 Speakers" bgColor="#fff" />
      <SpeakerCardAll speakers={newspeakers} chairman={chairman} />

      <Footer />
    </>
  );
}

export default PrivacyPolicy;
