import React from "react";
import { Container, Row, Col } from "reactstrap";

import "react-circular-progressbar/dist/styles.css";
// import SpeakerCard from "./SpeakerCard";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  return (
    <>
      <section id="about">
        <div
          style={{
            backgroundImage:
              size && `url(${require("assets/images/qatarbus.jpeg")})`,
            backgroundSize: size ? "100%" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
          className="section py-0"
          data-parallax={true}
          ref={pageHeader}
        >
          <Container>
            <Row className="justify-content-center">
              <Col
                md={8}
                xs={12}
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              >
                <div
                  className="text-white"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 4rem 1rem",
                  }}
                >
                  <h1
                    className="header-text text-left"
                    style={{ fontSize: size && "5rem" }}
                  >
                    OVERVIEW
                  </h1>
                  <br />
                  <br />
                  <p className="text-400  text-left">
                    The last two years have propelled the world into an era of
                    unparalleled digital transformation in banking and fintech.
                    What was once perceived as a temporary disruption has
                    evolved into an unstoppable force, shaping the new world
                    order. As we stand on the precipice of this transformative
                    journey, digitization emerges as the linchpin of virtually
                    every industry. In this dynamic landscape, fintech
                    innovations are not merely meeting evolving customer
                    expectations; they are redefining the very concepts of
                    customers and currency.
                    <br />
                    <br />
                    <b style={{ color: "#fff" }}>
                      {" "}
                      In the wake of remarkable global fintech growth, surging
                      by an astounding 72% over the past two years due to
                      innovative technologies, the MENA banking sector has
                      emerged as a resilient and adaptive force, boasting assets
                      exceeding $3.5 trillion.
                    </b>
                    <br />
                    <br />
                    BigTech and Fintech giants are converging on the banking
                    sector at an unprecedented pace. Banks have no choice but to
                    embrace digital banking and fintech's agility to remain
                    aligned with shifting market dynamics and customer demands.
                    <br />
                    <br />
                    <b style={{ color: "#fff" }}>
                      Over 85% of banking executives agree that the convergence
                      of technology companies and banks is a defining trend.
                    </b>
                  </p>
                  <br />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: "#fff",
            backgroundImage: `url(${require("assets/images/61769.jpg")})`,
            backgroundSize: size ? "100%" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          <Container>
            <Row>
              {" "}
              <Col md={8} xs={12}>
                <div
                  className="text-dark"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 4rem 1rem",
                  }}
                >
                  <h4 className="text-400  text-left">
                    Khaleej Times has witnessed the rise of the banking and
                    fintech sectors in the region and their pivotal role in
                    shaping the economic powerhouses that the GCC countries are
                    today. While technology has often taken the spotlight,
                    fintech's true capabilities and impact in banking have
                    rarely been discussed on such a grand stage. Enter Khaleej
                    Times' Banking, Innovation, and Technology Summit, Doha,
                    Qatar 2024 (BIT'24 Qatar Edition).
                    <br />
                    <br />
                    <b style={{ color: "#639ccc" }}>
                      BIT'24 Qatar edition will unite the world's foremost
                      thought leaders, innovators, and service providers. It's
                      an unprecedented deep dive into the latest trends,
                      opportunities, and challenges confronting the banking and
                      fintech sectors
                    </b>
                  </h4>
                  <br />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div
          className="section"
          style={{
            backgroundColor: "transparent",
            backgroundImage: `url(${require("assets/images/qatar.jpeg")})`,
            backgroundSize: size ? "100%" : "cover",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          <Container>
            <Row className="justify-content-start">
              <Col md={7} xs={12}>
                <div
                  className="text-white"
                  style={{
                    padding: size
                      ? "0rem 1rem 3rem 1rem"
                      : "3rem 1rem 4rem 1rem",
                  }}
                >
                  <h4 className="text-400  text-left">
                    Qatar's banking realm stands as a beacon of fintech
                    innovation, spearheading transformative technologies and
                    avant-garde business paradigms to cater to the evolving
                    preferences of its clientele. Unveiling a groundbreaking
                    narrative, the 2023 Qatar Banking Sector report, released on
                    October 8, unveils a dynamic landscape where financial
                    institutions embrace digital metamorphosis, underscore the
                    pivotal role of sustainability, and align with the
                    government's initiatives to nurture a robust fintech
                    ecosystem.
                    <br />
                    <br />
                    <b style={{ display: 'none', color: "#fff" }}>
                      The event will be taking place in Doha, Qatar on the 27th
                      of May, 2024.
                    </b>
                  </h4>
                  <br />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="section"
          style={{
            backgroundColor: "transparent",
            backgroundRepeat: "no-repeat",
            backgroundAttachment: "fixed",
          }}
        >
          {" "}
          <Container>
            <h1
              className="header-text text-left mb-4"
              style={{ fontSize: size ? "3rem" : "2rem" }}
            >
              QATAR PAYMENT REVOLUTION 2023: <br />
              UNLEASHING THE FUTURE OF FINANCIAL FREEDOM
            </h1>
            <Row className="justify-content-start">
              {content.map((c) =>
                c.type === "IMAGE" ? (
                  <Col lg={6}>
                    <img
                      src={require("assets/images/qatarstats.png")}
                      alt={"wevalue"}
                      width="100%"
                      style={{ maxWidth: 300 }}
                      className="rounded-0"
                    />
                    <h5 className="text-600 mt-2">
                      <a className="text-600" href={c.source}>
                        Source
                      </a>
                    </h5>
                  </Col>
                ) : (
                  <Col md={c.grid} xs={12}>
                    <div
                      className="text-dark"
                      // style={{ padding: size ? "0rem 1rem 3rem 1rem" : "3rem 1rem 4rem 1rem" }}
                    >
                      <h5 className="text-600 mt-2">{c.title}</h5>
                      <p className="text-400  text-left">
                        <ul>
                          {c.points.map((p) => (
                            <li>{p}</li>
                          ))}
                        </ul>
                      </p>
                      <br />
                    </div>
                  </Col>
                )
              )}
            </Row>
          </Container>
        </div>
      </section>

      {/* <section id="speakers">
        <SpeakerCard />
      </section> */}
    </>
  );
}

export default TextGrid;

const content = [
  {
    title: "Dynamic Payment Landscape",
    points: [
      "Card Revolution: Over 90% of SMEs embrace debit and credit cards, unleashing a cashless wave.",
      `QMPS Magic: Qatar Central Bank's (QCB) game-changing "Qatar Mobile Payment System" (QMPS) transforms electronic wallets, revolutionizing the payment arena.`,
      "QR Code Fusion: QCB unifies QR code standards, enabling seamless payments through mobiles at sale points and public transport, elevating convenience.",
      "Smartphone Surge: Fueled by high smartphone penetration and the pandemic, banks overhaul mobile banking, with the majority offering cutting-edge services and apps.",
    ],
    grid: 6,
  },
  {
    type: "IMAGE",
    src: require("assets/images/Asset2.png"),
    source:
      "https://fintechnews.ae/15457/fintech/2023s-top-10-fintech-companies-in-the-middle-east/",
  },

  {
    title: "Fintech Marvels Unveiled",
    points: [
      `National Fintech Strategy: QCB's March 2023 launch integrates Islamic banking, insurance tech, and edtech, aiming to regulate and enable emerging fintech models.`,
      `License to Thrive: Fintech services, including Payment Services Providers (PSPs) and "buy now pay later" (BNPL), must secure QCB licenses to operate, fostering a regulated innovation hub.`,
    ],
    grid: 6,
  },
  {
    title: "Payment Prowess",
    points: [
      `Diverse Methods: From debit/credit cards to bank transfers, Qatar embraces a variety of payment methods.`,
      `Tech-Powered Tap: Contactless cards, smartphones, and smartwatches rule the payment scene, with PIN-free transactions up to $137 USD for a seamless tap-and-go experience.`,
      `World Cup Wonders: FIFA World Cup 2022 witnesses’ innovations like "Pay with your Face" and checkout-free experiences, transforming the payment landscape.`,
    ],
    grid: 6,
  },
  {
    title: "Unlocking Opportunities",
    points: [
      `Strategic Hub: Qatar's strategic Gulf location and economic diversification efforts make it an enticing market for financial services providers.`,
      `Investment Oasis: Significant investments in banking, fintech, and asset management present niche opportunities for U.S. financial solution providers.`,
      `Innovation Oasis: Fintech-friendly Qatar beckons U.S. innovators, offering incentives for startups and a market eager for groundbreaking solutions.`,
    ],
    grid: 6,
  },
];
