export const PastSponserDubai2024 = [

    {
        "id": "cdceceba-a08a-4286-97e8-49e05946a254",
        "name": "Backbase",
        "slug": "Backbase",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120006/sponsors/1710830853126.png",
        "type": "DIGITAL BANKING PARTNER",
        "url": "https://www.backbase.com/",
        "panel": 3,
        "size": 4,
        "priority": -1,
        "isActive": false
    },
    {
        "id": "065d9cb6-a0fb-4d08-8621-780b8c0e3480",
        "name": "FRESHWORKS",
        "slug": "FRESHWORKS",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120006/sponsors/1716368588402.png",
        "type": "TECHNOLOGY PARTNER",
        "url": "https://www.freshworks.com/",
        "panel": 3,
        "size": 4,
        "priority": 0,
        "isActive": false
    },
    {
        "id": "4e11461c-a565-49b5-b432-8e3abc351657",
        "name": "Wego Pro",
        "slug": "Wego Pro",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120006/sponsors/1716191590845.png",
        "type": "TRAVEL PARTNER",
        "url": "https://www.wegopro.com/",
        "panel": 3,
        "size": 4,
        "priority": 0,
        "isActive": false
    },
    {
        "id": "430c9cdc-0385-47ec-8379-e91864be3bdf",
        "name": "Mena Fintech",
        "slug": "Mena Fintech",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120006/sponsors/1710830591742.png",
        "type": "ECOSYSTEM PARTNER",
        "url": "https://mena-fintech.org/",
        "panel": 3,
        "size": 4,
        "priority": 1,
        "isActive": false
    },
    {
        "id": "4654756c-d87f-4a07-83c3-db0848d81dab",
        "name": "Mckinsey & Company",
        "slug": "Mckinsey",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120006/sponsors/1713860217294.png",
        "type": "KNOWLEDGE PARTNER",
        "url": "https://www.mckinsey.com/",
        "panel": 3,
        "size": 4,
        "priority": 2,
        "isActive": false
    },
    {
        "id": "e055bf75-393f-43e6-b36a-3bcb76a85025",
        "name": "FinTech Surge",
        "slug": "FinTech Surge",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120006/sponsors/1716191679042.png",
        "type": "STRATEGIC EVENT PARTNER",
        "url": "https://www.fintechsurge.com/",
        "panel": 3,
        "size": 4,
        "priority": 3,
        "isActive": false
    },
    {
        "id": "60a2cf58-b70e-4342-a56d-9d3619701df4",
        "name": "GrowX",
        "slug": "GrowX",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120006/sponsors/1713859323831.png",
        "type": "VENTURE CAPITAL PARTNER",
        "url": "https://growxventures.com/",
        "panel": 3,
        "size": 4,
        "priority": 4,
        "isActive": false
    },
    {
        "id": "9da9be3e-10ac-4583-a518-1a9416c305b5",
        "name": "237 Ventures",
        "slug": "237 Ventures",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120006/sponsors/1713859361915.png",
        "type": "INNOVATION PARTNER",
        "url": "https://www.237ventures.com/",
        "panel": 3,
        "size": 4,
        "priority": 5,
        "isActive": false
    },
    {
        "id": "d7af8971-5926-4336-a0bb-a7dab0b0422a",
        "name": "The Founders Majlis",
        "slug": "The Founders Majlis",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120006/sponsors/1713961585057.png",
        "type": "STRATEGIC PARTNER",
        "url": null,
        "panel": 3,
        "size": 4,
        "priority": 6,
        "isActive": false
    },
    {
        "id": "3f2b0dce-2a93-4183-9b5c-f1244943c0a4",
        "name": "India Accelerator",
        "slug": "India Accelerator",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120006/sponsors/1714496424858.png",
        "type": "INTERNATIONAL ACCELERATOR PARTNER",
        "url": null,
        "panel": 3,
        "size": 4,
        "priority": 7,
        "isActive": false
    },
    {
        "id": "fbc9ba07-2107-4c41-bf70-971d9d19a1f6",
        "name": "BTR",
        "slug": "BTR",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120006/sponsors/1713859467452.png",
        "type": "MEDIA PARTNER",
        "url": " ",
        "panel": 3,
        "size": 4,
        "priority": 40,
        "isActive": false
    },
    {
        "id": "58555298-1913-4272-b39e-0787fef98ec3",
        "name": "Emirates NBD",
        "slug": "Emirates-NBD",
        "bio": "<p>Emirates NBD (DFM: Emirates NBD) is a leading banking group in the MENAT (Middle East, North Africa and Türkiye) region with a presence in 13 countries, serving over 20 million customers. As at 30th September 2023, total assets were AED 836 billion, (equivalent to approx. USD 228 billion). The Group has operations in the UAE, Egypt, India, Türkiye, the Kingdom of Saudi Arabia, Singapore, the United Kingdom, Austria, Germany, Russia and Bahrain and representative offices in China and Indonesia with a total of 853 branches and 4,213 ATMs / SDMs. Emirates NBD is the leading financial services brand in the UAE with a Brand value of USD 3.89 billion.</p><p>Emirates NBD Group serves its customers (individuals, businesses, governments, and institutions) and helps them realise their financial objectives through a range of banking products and services including retail banking, corporate and institutional banking, Islamic banking, investment banking, private banking, asset management, global markets and treasury, and brokerage operations. The Group is a key participant in the global digital banking industry with 97% of all financial transactions and requests conducted outside of its branches. The Group also operates Liv, the lifestyle digital bank by Emirates NBD, with close to half a million users, it continues to be the fastest-growing bank in the region.</p><p>Emirates NBD contributes to the construction of a sustainable future as an active participant and supporter of the UAE’s main development and sustainability initiatives, including financial wellness and the inclusion of people of determination. Emirates NBD is committed to supporting the UAE’s Year of Sustainability as Principal Banking Partner of COP28 and an early supporter to the Dubai Can sustainability initiative, a city- wide initiative aimed to reduce use of single-use plastic bottled water.</p>",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1698843012401.png",
        "type": "HEADLINE SPONSOR",
        "url": "https://www.emiratesnbd.com/en",
        "panel": 1,
        "size": 4,
        "priority": 1,
        "isActive": false
    },
    {
        "id": "1730ad80-3ca6-4509-bb55-98134b6a2420",
        "name": "Airpay",
        "slug": "Airpay",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1697865284230.png",
        "type": "LEAD SPONSOR",
        "url": "https://www.airpay.com/",
        "panel": 2,
        "size": 4,
        "priority": 1,
        "isActive": false
    },
    {
        "id": "26ccea90-1782-4a70-a1bf-3b931b7ba943",
        "name": "Mashreq",
        "slug": "Mashreq",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1698135186170.jpg",
        "type": "DIGITAL PARTNER",
        "url": "https://www.mashreqbank.com/en/uae/personal/",
        "panel": 3,
        "size": 3,
        "priority": 1,
        "isActive": false
    },
    {
        "id": "16b2a063-a5f3-48f6-a7c7-c892f971f1ae",
        "name": "Al Fardan Exchange",
        "slug": "Al-Fardan",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1698843880872.png",
        "type": "ASSOCIATE SPONSOR",
        "url": "https://alfardanexchange.com/",
        "panel": 4,
        "size": 3,
        "priority": 2,
        "isActive": false
    },
    {
        "id": "db703d68-748b-46a4-a7b5-7d4be5f01274",
        "name": "Al Ansari",
        "slug": "Al Ansari",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1698843188126.png",
        "type": "ASSOCIATE SPONSOR",
        "url": "https://alansariexchange.com/",
        "panel": 4,
        "size": 3,
        "priority": 2,
        "isActive": false
    },
    {
        "id": "4ac29497-c138-4295-901b-54ff9c89018c",
        "name": "Standard Chartered Bank",
        "slug": "st",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1697865320442.png",
        "type": "ASSOCIATE SPONSOR",
        "url": "https://www.sc.com",
        "panel": 4,
        "size": 3,
        "priority": 3,
        "isActive": false
    },
    {
        "id": "d3ca0331-c8da-4fb1-a217-1b2738d7fdbf",
        "name": "Icogz",
        "slug": "Icogz",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1697865348932.png",
        "type": "ANALYTICS PARTNER",
        "url": "https://icogz.com/",
        "panel": 4,
        "size": 3,
        "priority": 4,
        "isActive": false
    },
    {
        "id": "c43faacf-2a49-4b88-b7af-7d3363489f5c",
        "name": "Collinson",
        "slug": "Collinson",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1698843432518.png",
        "type": "LOYALTY PARTNER",
        "url": "https://www.collinsongroup.com/",
        "panel": 5,
        "size": 3,
        "priority": 1,
        "isActive": false
    },
    {
        "id": "7951d47f-7b55-42db-918b-b59d322889a9",
        "name": "Mckinsey",
        "slug": "Mckinsey",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1698135225940.png",
        "type": "KNOWLEDGE PARTNER",
        "url": "https://www.mckinsey.com/",
        "panel": 5,
        "size": 3,
        "priority": 2,
        "isActive": false
    },
    {
        "id": "aa98b0df-ca76-41f7-a9dd-5b9ac1540326",
        "name": "Credentek",
        "slug": "Credentek",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1698130173995.png",
        "type": "NETWORKING PARTNER",
        "url": "https://www.credentek.com/",
        "panel": 5,
        "size": 3,
        "priority": 3,
        "isActive": false
    },
    {
        "id": "f824dce8-beb5-472e-ad43-9e3da1da5b61",
        "name": "Wavetec",
        "slug": "Wavtek",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1699593650026.png",
        "type": "NETWORKING PARTNER",
        "url": "https://www.wavetec.com/",
        "panel": 5,
        "size": 3,
        "priority": 4,
        "isActive": true
    },
    {
        "id": "6f0ef4b5-cfff-4ffe-9ce2-bf25ee8e9c99",
        "name": "Fintech Tuesday",
        "slug": "Fintech-Tuesday",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1697865414547.png",
        "type": "SUPPORTED BY",
        "url": "https://www.fintechsurge.com/fintech-tuesdays",
        "panel": 6,
        "size": 3,
        "priority": 1,
        "isActive": false
    },
    {
        "id": "f84c0b6e-dbff-4645-ab44-674a1bc89156",
        "name": "UBF",
        "slug": "UBF",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1698843949716.jpg",
        "type": "ENDORSED BY",
        "url": "https://www.uaebf.ae/en/index",
        "panel": 6,
        "size": 3,
        "priority": 2,
        "isActive": false
    },
    {
        "id": "46a82797-921a-409a-979c-27bcaee1dbe2",
        "name": "We Value",
        "slug": "We Value",
        "bio": "",
        "image": "https://explat-space.ams3.digitaloceanspaces.com/d81e47ba-55df-11ee-8c99-0242ac120002/sponsors/1698843581758.png",
        "type": "CO-PRESENTED BY",
        "url": "https://wevalue.io/",
        "panel": 6,
        "size": 3,
        "priority": 4,
        "isActive": false
    }
];

// for all the Editions
export const AllEditions = [
    { href: "/", name: "BIT'23 UAE" },
    { href: "/qatar", name: "BIT'24 Doha" },
    { href: "/dubai", name: "BIT'24 Dubai" },
    { href: "/riyadh", name: "BIT'25 KSA" }
];
// for all the Editions