export const token =
  "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJldmVudElkIjoiZDgxZTQ3YmEtNTVkZi0xMWVlLThjOTktMDI0MmFjMTIwMDAyIiwiY3VzdG9tZXJJZCI6IjY3MDk5ZGU4LWNhZjktMTFlZC1hZmExLTAyNDJhYzEyMDAwMiIsImhvc3QiOiJodHRwczovL2JpdC5raGFsZWVqdGltZXNldmVudHMuY29tIiwiaWF0IjoxNjk1MjI3MTk2LCJhdWQiOiJleHAuY29tIiwiaXNzIjoiZXhwLXByb3ZpZGVyIiwic3ViIjoiYWRtaW5AZXhwLmNvbSJ9.cowzhn_y_9xHeCVY-eUeq-T8GtUN3NnB-Eco_PYWoixJMbsjTXdoSspC7cY1lQvWttglciG75XQpTDtZRbphY1_TYzlJdZrmoI1vrYMZa8myg-EQ-0qZReEHTV5IOjl-81BCFrXhI96o1roi8jI9mMPRuvit3tmERyWmRtGE5Qw";

// export const apiURL = "https://api.rapid-events.com";
export const apiURL = "/";
// export const apiURL = "http://localhost:3012";
//

export const qatarToken =
  "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJldmVudElkIjoiZDgxZTQ3YmEtNTVkZi0xMWVlLThjOTktMDI0MmFjMTIwMDA2IiwiY3VzdG9tZXJJZCI6IjY3MDk5ZGU4LWNhZjktMTFlZC1hZmExLTAyNDJhYzEyMDAwMiIsImhvc3QiOiJodHRwczovL2JpdC5raGFsZWVqdGltZXNldmVudHMuY29tIiwiaWF0IjoxNzAyNzQwNjIyLCJhdWQiOiJleHAuY29tIiwiaXNzIjoiZXhwLXByb3ZpZGVyIiwic3ViIjoiYWRtaW5AZXhwLmNvbSJ9.F45ZV6gWn6B1pJ0Yz0zpJMvGTr-48zKLkEG_Y1BgOIGXWm3VLXB7ObDonecRNZgvxir4NGDC0vMploYf7hqIT75bHLdDEGzUa9ssOiUcfA2_PwBP8AT70CaEoTapRLYJIobFdwjdUWAciAIZbFTvtAgN8WDam6YObuBJ11hXbco";
